import { useEffect, useRef } from "react";
import { useMediaQuery } from 'react-responsive';


function OutbreakSurvivors() {
  const ref = useRef(null)
  const scroll = () => ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  const isMobile = useMediaQuery({ query: `(max-width: 576px)` });

  useEffect(() => {
    document.getElementById('vid').play();
  }, [])

  return (
    <>
      <div className="title-container">
        {isMobile && <img width={'300'} className="title" alt="title" src="https://littlenapstudio.com/assets/OutbreakSurvivorsLogo.png" />}
        {!isMobile && <img width={'480'} className="title" alt="title" src="https://littlenapstudio.com/assets/OutbreakSurvivorsLogo.png" />}

      </div>

      <div className="outbreak-suvivors">
        <div className="banner-background-container">
          <div className="banner-breakpoints">
            <video muted id="vid" loop>
              <source src="https://littlenapstudio.com/assets/WebpageAnimation.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div className="arrow-container">
          <button onClick={scroll}>
            <svg fill='white' xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M12 2a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8z" /><path d="M12 12.586 8.707 9.293l-1.414 1.414L12 15.414l4.707-4.707-1.414-1.414L12 12.586z" /></svg>
          </button>
        </div>
      </div>

      <div>
        <p className="trailer-title">Trailer</p>
      </div><div ref={ref} className="iframe-container">
        <iframe width="1600" height="1000" src="https://www.youtube.com/embed/5AQcf39SYvo?si=Y55Nr1XqyZinF72S" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div><p className="contact">contato@littlenapstudio.com</p>
      <div className="download-container">
        <a href="https://littlenapstudio.com/assets/OutbreakSurvivorsDemo.zip" download target="_blank" rel="noreferrer">
          <img alt="button" src="https://littlenapstudio.com/assets/DownloadBtn.png"></img>
        </a>
      </div>
    </>
  );
}

export default OutbreakSurvivors;
